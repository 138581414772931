.mainContainer {
    display: flex;
}

.content {
    flex: 1;
    padding: 1rem;
    width: 85vw;
    max-width: 1200px;
}

.image-array-container, .main-image-container{
    max-width: 1000px;
    overflow: hidden;
}


.header {
    background: #333;
    color: #fff;
    textAlign: center;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 97vw;
}

.header,h1 {
    padding-left: 1rem;
}

.signout {
    background: none;
    border: none;
    color: #007bff; /* Link color */
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    font: inherit;
}

.signout:hover {
    color: #0056b3; /* Hover color */
}
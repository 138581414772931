.properties-container {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px; /* 5px rounded corners */
    background-color: #dbe9ff;
    padding: 0 5px;
}

.forSale {
    background-color: #b2cba9;
}

.pending {
    background-color: #a1d290;
}

.sold {
    background-color: #6cce48;
}

.comingSoon {
    background-color: #dde1ef;
}

.forRent {
    background-color: #a9b1cb;
}

.rented {
    background-color: #8194d3;
}

.offMarket {
    background-color: #cba9ad;
}

.forSale.featured {
    background-image: linear-gradient(to bottom right, #b2cba9, #d9bb31);
}

.pending.featured {
    background-image: linear-gradient(to bottom right, #a1d290, #d9bb31);
}

.sold.featured {
    background-image: linear-gradient(to bottom right, #6cce48, #d9bb31);
}

.comingSoon.featured {
    background-image: linear-gradient(to bottom right, #dde1ef, #d9bb31);
}

.forRent.featured {
    background-image: linear-gradient(to bottom right, #a9b1cb, #d9bb31);
}

.rented.featured {
    background-image: linear-gradient(to bottom right, #8194d3, #d9bb31);
}

.main-image-image {
    margin: .5rem;
    max-height: 500px;
}

.image-array-image {
    margin: .5rem;
}

.sticky-filters {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0;
    background-color: silver;
    z-index: 1000;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}